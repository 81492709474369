<template>
  <div class="aboutPage">
    <div class="mainContainer">
      <TopMenu :show-logo="true"/>
      <div class="aboutContainer">
        <div class="aboutTitle">
          <IconsWorker icon="info" color="#555" :width="24"/>
          <span>من نحن ؟</span>
        </div>
        <div class="loadingAbout" v-if="state.isLoading">
          <IconsWorker icon="loading" :width="40" />
        </div>
        <div class="aboutUsContent" v-else>{{state.content}}</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import IconsWorker from "../components/IconsWorker";
import BrowserHelper from "../helpers/BrowserHelper";
import AjaxWorker from "../helpers/AjaxWorker";
import {onMounted, reactive} from "vue";
export default {
  components: {IconsWorker, Footer, TopMenu},
  setup() {
    BrowserHelper.changeTitle('من نحن');
    const state = reactive({
      isLoading: true,
      content: '',
    });
    const getData = () => {
      const keys = ["aboutUs"];
      state.isLoading = true;
      new AjaxWorker().request("/transactions.php?action=getSettings&keys=" + keys.join(',')).then((res) => {
        if(res === "NOINTERNETCONNECTION" || !res.state) {
          setTimeout(() => {
            getData();
          }, 3000);
        } else {
          state.content = res.data["aboutUs"];
          state.isLoading = false;
        }
      });
    }

    onMounted(() => {
      getData();
    });
    return {
      state,
    }
  }
}
</script>

<style scoped>
.aboutPage{
  background-color: #000;
  width: 100%;
  min-height: 100%;
  background-image: url("../assets/images/bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
}

.aboutContainer{
  width: 70%;
  padding: 30px 2.5%;
  margin: 50px 10%;
  background: #fff;
  box-shadow: 0 0 10px #000;
  border-radius: 10px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  text-align: start;
}

.aboutTitle{
  width: available;
  font-size: 17px;
  font-weight: bold;
  color: #555;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 25px;
  border-bottom: 1px solid #eee;
}

.aboutUsContent{
  font-size: 15px;
  font-weight: bold;
  color: #000;
  margin-top: 20px;
  white-space: pre-line;
  line-height: 22px;
}

.loadingAbout{
  display: flex;
  place-content: center;
  place-items: center;
  margin: 150px 0;
}

@media only screen and (max-width: 600px) {
  .aboutContainer {
    width: 80%;
    padding: 20px 5%;
    margin: 50px 5%;
  }
}
</style>
